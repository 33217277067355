import React, { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';
import qs from 'qs';

import Header from '../Header';
import Footer from '../Footer';

import WarningNoteDialog from '../../../../components/WarningNoteDialog';

import mapModuleToPathname from '../../../../data/mapModuleToPathname';

import parseStateToQueryParams from '../../../../helpers/parseStateToQueryParams';

import {
  GET_BASE_DATA,
} from './query';

import styles from './BasePage.module.scss';

const HEADER_HEIGHT = 0;
const HEADER_SIMPLE_HEIGHT = 0;
const FOOTER_HEIGHT = 30;

function BasePage({
  history,
  location,
  children,
  simple = false
}) {
  const client = useApolloClient();
  const shouldNotifyUserNewTermOfUseVersion = localStorage.getItem('notify_user_new_term_of_use_version') === 'true';
  const locale = localStorage.getItem('locale') || 'es';
  const pathname = _.get(location, 'pathname');
  const search = _.get(location, 'search');
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const headerHeight = simple ? HEADER_SIMPLE_HEIGHT : HEADER_HEIGHT;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - (headerHeight + FOOTER_HEIGHT));
  const isFrameMode = pathname === '/agua' ||
    pathname === '/monitor-do-fogo' ||
    pathname === '/pistas-de-pouso' ||
    pathname === '/dinamica-dos-pivos';

  const app = _.get(baseData, 'app');

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  useEffect(() => {
    if (shouldNotifyUserNewTermOfUseVersion) {
      updateClientAppState({ notifyUserNewTermOfUseVersion: true });
    }

    window.addEventListener('resize', debouncedUpdateHeight);

    return () => {
      window.removeEventListener('resize', debouncedUpdateHeight)
    };
  }, []);

  useEffect(() => {
    // Listen for app state changes and update query params on URL
    const parsedQueryParams = parseStateToQueryParams(app);
    const encodedQueryParams = qs.stringify(parsedQueryParams, {
      encodeValuesOnly: true,
      arrayFormat: 'comma'
    });
    const activeModule = _.get(app, 'activeModule');
    const mapModuleKeyToPathname = _.invert(mapModuleToPathname);
    const customPathname = mapModuleKeyToPathname[activeModule] || pathname;

    if (!isFrameMode) {
      history.replace(`${ customPathname }?${ encodedQueryParams }`);
    }
  }, [app]);

  useEffect(() => {
    if (pathname === '/agua') {
      updateClientAppState({ activeModuleContent: 'water:water_iframe' });
    } else if (pathname === '/monitor-do-fogo') {
      updateClientAppState({ activeModuleContent: 'fire:fire_iframe' });
    } else if (pathname === '/pistas-de-pouso') {
      updateClientAppState({ activeModuleContent: 'infrastructure:infrastructure_iframe' });
    } else if (pathname === '/dinamica-dos-pivos') {
      updateClientAppState({
        activeModuleContent: 'irrigation:irrigation_iframe',
        activeNoteKey: 'irrigation_iframe',
      });
    }
  }, [pathname]);

  if (!baseData) { return null; }

  const {
    isLoadingBaseData,
  } = app || {};

  if (isLoadingBaseData) {
    return (
      <div className="lds-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function updateHeight() {
    setWindowHeight(window.innerHeight);
    setContentHeight(window.innerHeight - (headerHeight + FOOTER_HEIGHT));
  }
  const debouncedUpdateHeight = _.debounce(updateHeight, 500);

  return (
    <div
      className={ classnames(styles.pageWrapper, {
        'locale-en': locale === 'en'
      }) }
      style={ { height: windowHeight } }
    >
      <Header
        simple={ simple }
        height={ contentHeight }
        iframeMode={ isFrameMode }
        pathname={ pathname }
      />
      <div
        className={ styles.contentWrapper }
        style={{ minHeight: contentHeight }}
      >
        { !isFrameMode && children }
        { pathname === '/agua' &&
          <iframe
            width={ window.innerWidth - 60 }
            height={ contentHeight }
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="MapBiomas Água"
            src="https://plataforma.agua.mapbiomas.org/water/4.254025/-73.182578/4.8/panAmazon/countryPanAmazon/3/biomeCountryPanAmazon/surface/2000/2022"
          />
        }
        { pathname === '/monitor-do-fogo' &&
          <iframe
            width={ window.innerWidth - 60 }
            height={ contentHeight }
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="MapBiomas Monitor do Fogo"
            src="https://fogo.geodatin.com/"
          />
        }
        { pathname === '/pistas-de-pouso' &&
          <iframe
            width={ window.innerWidth - 60 }
            height={ contentHeight }
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="MapBiomas Pistas de Pouso"
            src="https://solvedgeo.users.earthengine.app/view/pistas-de-pouso"
          />
        }
        { pathname === '/dinamica-dos-pivos' &&
          <iframe
            width={ window.innerWidth - 60 }
            height={ contentHeight }
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="MapBiomas Dinâmica dos Pivôs"
            src="https://mapbiomas-workspace.earthengine.app/view/mapbiomas-agriculture-pivots"
          />
        }
      </div>
      <Footer />
      <WarningNoteDialog />
    </div>
  );
}

export default BasePage;
