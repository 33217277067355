import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TerritoryAutocomplete from '../MapBiomasLandingPage/layout/Header/components/BaseForm/components/TerritoryAutocomplete';
import ModuleIconComponent from '../MapBiomasLandingPage/layout/Header/ModuleIconComponent';

import PageFooter from '../MapBiomasLogin/components/PageFooter';

import CoverageMainStatisticsContent from './content/CoverageMainStatisticsContent';
import CoveragePieChartContent from './content/CoveragePieChartContent';
import AnnualStatisticsContent from './content/AnnualStatisticsContent';

import logo from './logo.svg';

import styles from './TerritoryDashboard.module.scss';

const baseParams = {
  "groupType": "territorial",
  "territory": 193,
  "territories": [
      {
          "id": 193,
          "label": "Altamira (PA)",
          "territoryTypeId": 4,
          "territoryTypeLabel": "Município",
          "territoryBBox": [
              [
                  -9.645178150999953,
                  -55.623870985999986
              ],
              [
                  -2.9887168529999784,
                  -51.64753782599993
              ]
          ],
          "__typename": "AppBaseParamsActiveTerritoryItemState"
      }
  ],
  "classesViewMode": "class",
  "activeClassTreeNodeIds": [],
  "activeClassTreeOptionValue": null,
  "activeObjectTreeNodeIds": [],
  "activeSubmodule": null,
  "activeClassesLevelsListItems": [
      1,
      7,
      8,
      9,
      10,
      2,
      11,
      12,
      13,
      14,
      15,
      16,
      3,
      17,
      18,
      27,
      37,
      38,
      39,
      40,
      41,
      28,
      42,
      43,
      44,
      19,
      20,
      4,
      21,
      22,
      23,
      24,
      5,
      25,
      26,
      6
  ],
  "activeTransitionsClassesListItems": null,
  "activePastureQualityQualityClassesListItems": null,
  "yearRange": "1985-2021",
  "buffer": 0,
  "__typename": "AppBaseParamsState"
};

const COVERAGE_TREE_NODE_IDS = '1,7,8,9,10,2,11,12,13,14,15,16,3,17,18,27,37,38,39,40,41,28,42,43,44,19,20,4,21,22,23,24,5,25,26,6';
const COVERAGE_NATURAL_OR_ANTHROPIC_TREE_NODE_IDS = '48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,75,47';
const DEFORESTATION_TREE_NODE_IDS = '254,256,269,270,271,272,257,273,274,275,276,277,255,258,278,279,280,281,259,282,283,284,285,286';
const QUALITY_OF_PASTURE_TREE_NODE_IDS = '79,80,81';
const REGENERATION_TREE_NODE_IDS = '383,387,442,443,444,445,388,446,447,448,449,450,451,384,389,452,453,454,456,458,459,460,461,462,457,463,464,465,455';
const IRRIGATION_TREE_NODE_IDS = '181,184,189,190,191,252,185,192,193,194,182,186,195,196,197,253,187,198,199,200,183,188,201';
const MINING_TREE_NODE_IDS = '82,84,151,152,153,154,155,156,157,158,159,160,161,162,163,164,85,165,166,167,168,169,170,86,171,172,87,173,174,175,83,88,176,177,89,178,90,179,180';
const FIRE_TREE_NODE_IDS = '202,207,208,209,210,203,211,212,213,214,215,204,216,217,218,219,220,221,222,223,224,225,205,226,227,228,229,206,230,231';

function Triangle({
  title,
  subtitle,
  color
}) {
  return (
    <div className={ styles.sectionTitleBox }>
      <div className={ styles.triangleArrow } style={ { borderLeftColor: color } } />
      <div className={ styles.sectionTitleBoxTitleWrapper }>
        <h1 className={ styles.sectionTitleBoxTitle }>{ title }</h1>
        { subtitle &&
          <h2 className={ styles.sectionTitleBoxSubtitle }>{ subtitle }</h2>
        }
      </div>
    </div>
  );
}

const MODULES = [
  { key: 'coverage', label: 'Cobertura' },
  // { key: 'coverage_quality', label: '' },
  // { key: 'temporal_analysis', label: '' },
  { key: 'quality_of_pasture_data', label: 'Pastagem' },
  { key: 'deforestation', label: 'Desmatamento' },
  { key: 'regeneration', label: 'Regeneração' },
  // { key: 'infrastructure', label: '' },
  { key: 'irrigation', label: 'Irrigação' },
  { key: 'fire', label: 'Fogo' },
  // { key: 'scenarios', label: '' },
  { key: 'water', label: 'Água' },
  { key: 'mining', label: 'Mineração' },
];

export default function TerritoryDashboard() {
  const [territory, setTerritory] = useState({
    "id": 193,
    "label": "Altamira (PA)",
    "boundingBox": [
      [
        -9.645178150999953,
        -55.623870985999986
      ],
      [
        -2.9887168529999784,
        -51.64753782599993
      ]
    ]
  });

  const bbox = _.get(territory, 'boundingBox');
  const territoryId = _.get(territory, 'id');
  const territoryBoundingBox = `${ _.get(bbox, '[0][1]') },${ _.get(bbox, '[0][0]') },${ _.get(bbox, '[1][1]') },${ _.get(bbox, '[1][0]') }`;

  const handleTerritoryChange = (data) => {
    console.log('territory change', data);
    setTerritory(data);
  };

  const renderTerritoryImage = (module, year, nodeIds) => {
    const wfsParams = [
      'service=WMS',
      'request=GetMap',
      `layers=${ module }`,
      'format=image/png',
      'transparent=true',
      'version=1.1.1',
      `territory_ids=${ territoryId }`,
      `year=${ year }`,
      `class_tree_node_ids=${ nodeIds }`,
      'width=220',
      'height=220',
      'srs=EPSG:4326',
      `bbox=${ territoryBoundingBox }`
    ];
    const params = wfsParams.join('&');

    return (
      <div className={ styles.imageBox }>
        <img src={ `https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/wms?service=WMS&request=GetMap&layers=mapbiomas-alertas:dashboard_cities-static-layer&format=image/png&transparent=true&version=1.1.1&width=220&height=220&srs=EPSG:4326&bbox=${ territoryBoundingBox }`} />
        <img src={ `https://brasil.mapserver.mapbiomas.org/wms/${ module }.map?${ params }`} />
      </div>
    );
  };

  const renderSectionTitle = (title, subtitle, color) => {
    return (
      <Triangle
        title={ title }
        subtitle={ subtitle }
        color={ color }
      />
    );
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.content }>
        <a href="https://mapbiomas.org/">
          <img src={ logo } className={ styles.logo } alt="Logo MapBiomas" />
        </a>
        <Paper className={ styles.formBox }>
          <TerritoryAutocomplete
            activeTerritories={ [territory] }
            categoryId={ 4 }
            label={ null }
            placeholder="Selecione um município..."
            onChange={ handleTerritoryChange }
          />
        </Paper>
        <Paper className={ styles.contentBox }>
          <div className={ styles.contentWrapper }>
            <div className={ styles.contentTitleWrapper }>
              <h1 className={ styles.contentTitle }>Conheça os dados do<br />município de<br /><b>{ _.get(territory, 'label') }</b></h1>
              <p>Nesse relatório você terá dados sobre cobertura, desmatamento, regeneração, pastagem, irrigação, mineração, água e fogo</p>
            </div>
            <div className={ styles.modulesBlock }>
              <Grid container spacing={ 2 } alignItems="center">
                { _.map(MODULES, (module) => {
                  return (
                    <Grid item xs={ 3 } key={ module.key }>
                      <li className={ styles.moduleBlock }>
                        <span className={ styles.moduleBlockIcon }>
                          <ModuleIconComponent moduleKey={ module.key } />
                        </span>
                        <span className={ styles.moduleBlockTitle }>{ module.label }</span>
                      </li>
                    </Grid>
                  );
                }) }
              </Grid>
            </div>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle(_.get(territory, 'label')) }
              </Grid>
              <Grid item xs={ 6 }>
                { renderTerritoryImage('coverage', 2021, COVERAGE_TREE_NODE_IDS) }
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Cobertura e Uso da Terra', undefined, '#16a085') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('coverage', 2021, COVERAGE_TREE_NODE_IDS) }
              <CoverageMainStatisticsContent
                activeYear={ 2021 }
                territoryId={ territoryId }
              />
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Cobertura e Uso da Terra', 'Natural e Antrópico', '#16a085') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } alignItems="center" justifyContent="space-evenly">
              <Grid item xs={ 6 } className={ styles.yearBlockImage }>
                <h2 className={ styles.yearTitle }>2000</h2>
                { renderTerritoryImage('coverage', 2000, COVERAGE_NATURAL_OR_ANTHROPIC_TREE_NODE_IDS) }
                <CoveragePieChartContent
                  activeYear={ 2000 }
                  classTreeKey="natural_or_anthropic"
                  territoryId={ territoryId }
                />
              </Grid>
              <Grid item xs={ 6 } className={ styles.yearBlockImage }>
                <h2 className={ styles.yearTitle }>2022</h2>
                { renderTerritoryImage('coverage', 2022, COVERAGE_NATURAL_OR_ANTHROPIC_TREE_NODE_IDS) }
                <CoveragePieChartContent
                  activeYear={ 2021 }
                  classTreeKey="natural_or_anthropic"
                  territoryId={ territoryId }
                />
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Desmatamento', undefined, '#e74c3c') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('deforestation_annual', 2020, DEFORESTATION_TREE_NODE_IDS) }
              <AnnualStatisticsContent
                activeYear={ [1987, 2020] }
                classTreeKey="deforestation_annual_by_class"
                territoryId={ territoryId }
                themeColor="#e74c3c"
              />
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Vegetação Secundária', undefined, '#27ae60') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('regeneration_annual', 2019, REGENERATION_TREE_NODE_IDS) }
              <AnnualStatisticsContent
                activeYear={ [1987, 2019] }
                classTreeKey="regeneration_annual_by_class"
                territoryId={ territoryId }
                selectedClassTreeIds={ [383, 384] }
                themeColor="#27ae60"
              />
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Qualidade da Pastagem', undefined, '#f39c12') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('quality_of_pasture_data_main', 2021, QUALITY_OF_PASTURE_TREE_NODE_IDS) }
              <AnnualStatisticsContent
                activeYear={ [2000, 2021] }
                classTreeKey="quality_of_pasture_main"
                territoryId={ territoryId }
                themeColor="#f39c12"
              />
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Mineração', undefined, '#f39c12') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('mining_main', 2021, MINING_TREE_NODE_IDS) }
              <AnnualStatisticsContent
                activeYear={ [1985, 2021] }
                classTreeKey="mining_main"
                territoryId={ territoryId }
                themeColor="#f39c12"
              />
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Fogo', undefined, '#e74c3c') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('fire_annual', 2020, FIRE_TREE_NODE_IDS) }
              <AnnualStatisticsContent
                activeYear={ [1985, 2022] }
                classTreeKey="fire_annual"
                territoryId={ territoryId }
                themeColor="#e74c3c"
              />
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Irrigação', undefined, '#b731ce') }
              </Grid>
              <Grid item xs={ 6 }>
                <p className={ styles.sectionTitleBoxInfo }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent scelerisque elementum turpis vitae condimentum. Proin venenatis metus dui, a tempus massa posuere sed.</p>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              { renderTerritoryImage('irrigation_main', 2021, IRRIGATION_TREE_NODE_IDS) }
              <AnnualStatisticsContent
                activeYear={ [1985, 2021] }
                classTreeKey="irrigation_main"
                territoryId={ territoryId }
                themeColor="#b731ce"
              />
            </section>
          </div>
        </Paper>
      </div>
      <PageFooter/>
    </div>
  );
}
