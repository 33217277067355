import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';

import { useIntl } from 'react-intl';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

import { GET_BASE_DATA } from './query';

import styles from './YearTimelineMultiple.module.scss';

const useStyles = makeStyles((theme) => ({
  headerInfoValue: {
    color: theme.palette.primary.main
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '4px',
    fontSize: 12,
    color: '#F5F5F9',
    backgroundColor: '#5A5A5A'
  },
  arrow: {
    color: '#5A5A5A'
  },
  popper: {
    zIndex: 4000
  }
}))(Tooltip);

const intervals = [
  [1985, 1990],
  [1990, 1995],
  [1995, 2000],
  [2000, 2005],
  [2005, 2010],
  [2010, 2015],
  [2015, 2020],

  [2000, 2010],
  [2010, 2020],
  [1990, 2000],

  [1985, 2022],
  [1985, 2000],

  [2000, 2022],
  [1992, 2002],
  [2002, 2012],
  [2012, 2022],
];

export default function YearTimelineMultiple({
  headerIsVisible,
  value: initialValue,
  onChange = () => {},
}) {
  const intl = useIntl()

  const yearList = _.sortBy(_.uniq(_.flatten(intervals)));
  const startYearsList = _.sortBy(_.uniq(_.map(intervals, (range) => _.first(range))));

  const classes = useStyles();
  const { data: baseData } = useQuery(GET_BASE_DATA);

  const [startValue, setStartValue] = useState(2000);
  const [endValue, setEndValue] = useState(2022);

  const filteredIntervals = _.filter(intervals, (range) => _.first(range) === startValue);
  const endYearsList = _.sortBy(_.uniq(_.map(filteredIntervals, (range) => _.last(range))));

  // useEffect(() => setCurrentValue(initialValue), [initialValue]);
  // useEffect(() => setUpdateKey(_.uniqueId('timeline-')), [isRange, _.last(rangeLimits), _.last(rangeSpecificValues)]);

  const handleChange = (type, _event, newValue) => {
    if (type === 'start') {
      const filteredIntervals = _.filter(intervals, (range) => _.first(range) === newValue);
      const endYearsList = _.sortBy(_.uniq(_.map(filteredIntervals, (range) => _.last(range))));
      setStartValue(newValue);
      setEndValue(_.last(endYearsList));
    } else {
      setEndValue(newValue);
    }
  };

  const handleChangeComitted = (type, _event, newValue) => {
    if (type === 'start') {
      const filteredIntervals = _.filter(intervals, (range) => _.first(range) === newValue);
      const endYearsList = _.sortBy(_.uniq(_.map(filteredIntervals, (range) => _.last(range))));
      onChange([newValue, _.last(endYearsList)]);
    } else {
      onChange([startValue, newValue]);
    }
  };

  function ValueLabelComponent(props) {
    const { children, index, open, value } = props;

    let isOpen = open;
    let title = value;

    if (index === 1) {
      isOpen = true;
      title = `${ value } - ${intl.formatMessage({id: 'mapbiomas.timeline.selected_value'})}`;
    }

    return (
      <CustomTooltip
        arrow
        open={ isOpen }
        placement="top"
        title={ title }
      >
        { children }
      </CustomTooltip>
    );
  }

  let sliderItemsProps = {
    step: null,
    min: _.first(yearList),
    max: _.last(yearList),
  };

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperWithHeader]: headerIsVisible,
    }) } id="year-timeline">
      <div className={ styles.timelineInfo }>
        <DateRangeIcon />
      </div>
      <div className={ styles.sliderWrapper }>
        <Slider
          { ...sliderItemsProps }
          marks={ startYearsList.map((rsv) => ({ value: rsv })) }
          value={ startValue }
          track={ false }
          valueLabelDisplay="auto"
          ValueLabelComponent={ ValueLabelComponent }
          onChange={ handleChange.bind(this, 'start') }
          onChangeCommitted={ handleChangeComitted.bind(this, 'start') }
          classes={{
            mark: styles.timelineMark
          }}
        />
        <span className={ classnames(styles.valueText, classes.headerInfoValue) }>{ startValue } - { endValue }</span>
        <Slider
          { ...sliderItemsProps }
          marks={ endYearsList.map((rsv) => ({ value: rsv })) }
          value={ endValue }
          track={ false }
          valueLabelDisplay="auto"
          ValueLabelComponent={ ValueLabelComponent }
          onChange={ handleChange.bind(this, 'end') }
          onChangeCommitted={ handleChangeComitted.bind(this, 'end') }
          classes={{
            mark: styles.timelineMark
          }}
        />
      </div>
    </div>
  );
}
