export default [
  {
    index: 0,
    key: "country",
    labelKey: "country",
    color: "#FF0000",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Instituto Geográfico Nacional' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/nivel-politico-1.zip'
  },
  {
    index: 1,
    key: "province",
    labelKey: "province",
    color: "#FFA500",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:province"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Instituto Geográfico Nacional' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/nivel-politico-2.zip'
  },
  {
    index: 2,
    key: "department",
    labelKey: "department",
    color: "#EA55E0",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:department"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Instituto Geográfico Nacional' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/nivel-politico-3.zip'
  },
  {
    index: 3,
    key: "ecorregion",
    labelKey: "ecorregion",
    color: "#098812",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:ecorregion"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Bukart' },
      { labelKey: 'year', label: 'Ano', value: '1999' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/ecorregion.zip'
  },
  {
    index: 4,
    key: "vegetation_unit",
    labelKey: "vegetation_unit",
    color: "#06F216",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:vegetation_unit"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Oyarzabal et al' },
      { labelKey: 'year', label: 'Ano', value: '2018' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/unidades-de-vegetacion.zip'
  },
  {
    index: 5,
    key: "regiones_forestales",
    labelKey: "regiones_forestales",
    color: "#00AA0B",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:regiones_forestales"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'UMSEF' },
      { labelKey: 'year', label: 'Ano', value: '2014' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/regiones-forestales.zip'
  },
  {
    index: 6,
    key: "watersheds",
    labelKey: "watersheds",
    color: "#05D1FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:watersheds"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'COHIFE' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/cuencas-hidrograficas.zip'
  },
  {
    index: 7,
    key: "national_protected_areas",
    labelKey: "national_protected_areas",
    color: "#9FE2BF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:national_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SIB' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/area-protegida-nacional.zip'
  },
  {
    index: 8,
    key: "international_protected_areas",
    labelKey: "international_protected_areas",
    color: "#DFFF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:international_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Protected Planet' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/area-protegida-internacional.zip'
  },
  {
    index: 9,
    key: "subnational_protected_areas",
    labelKey: "subnational_protected_areas",
    color: "#27AE60",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:subnational_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Protected Planet' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/area-protegida-subnacional.zip'
  },
  {
    index: 10,
    key: "important_bird_areas",
    labelKey: "important_bird_areas",
    color: "#DAF7A6",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:important_bird_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Giacomo' },
      { labelKey: 'year', label: 'Ano', value: '2007' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/areas-importantes-para-la-consevacion-de-las-aves.zip'
  },
  {
    index: 11,
    key: "key_biodiversity_areas",
    labelKey: "key_biodiversity_areas",
    color: "#A708CB",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-argentina:key_biodiversity_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'BirdLife International, 2023' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/argentina/collection-1/download/layers/areas-claves-para-la-biodiversidad.zip'
  }
];
